import React from "react"

function Footer() {
  return (
    <footer className="footer">
      <div className="footer__content">
        ©2020 @makibishi0212 All Rights Reserved.
      </div>
    </footer>
  )
}

export default Footer
