import React from "react"
import { Link } from "gatsby"

function Header() {
  return (
    <header className="header">
      <h2 className="header__title">
        <Link to={"/"}>&gt; makibishi throw|</Link>
      </h2>
    </header>
  )
}

export default Header
