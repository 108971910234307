import React from "react"

import Header from "./header"
import Footer from "./footer"

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`

    if (location.pathname === rootPath) {
      // rootの場合
    }

    return (
      <>
        <Header />
        <div className="page-content markdown-body">
          <main>{children}</main>
        </div>
        <Footer />
      </>
    )
  }
}

export default Layout
